import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import { AiFillBank } from "react-icons/ai";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FaCoins, FaSortDown, FaSortUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  N_transectionHistory,
  N_ScreenShot,
} from "./redux/helpers/api_functions_new";

export default function AssetsFiat() {
  const { user, profile } = useSelector((state) => state.AuthReducer);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const [req_no, setReqno] = useState("");
  const [price, setPrice] = useState(0);
  const [filedata, setFileData] = useState();
  const [deposithistory, setDepositHistory] = useState();
  const [isError, SetIsError] = useState("");
  const [controller, setController] = useState(null);
  const uploadIMG = (input) => {
    if (input.target.files && input.target.files[0]) {
      console.log("fileset****");
      setFileData(input.target.files[0]);
    }
  };

  useEffect(() => {
    const token = user?.params ? user.params.token : user.token;
    let data = {
      type:"deposit",
      symbol: "INR",
    };
    N_transectionHistory(data, token)
      .then((data) => {
        if (data.status === 200) {
          setDepositHistory(data?.result);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="marginTop">
            <div className="account_page">
              <div className="container">
                <div className="page-content-title"></div>
                <div className="col-lg-12">
                  <div className="flex_row mb-4">
                    <div className="">
                      <div class="">
                        <h3 className=""> Deposit Fiat </h3>
                      </div>
                    </div>
                    <div className="">
                      <Link to="AssetsDeposit" className="btn btn-light">
                        Deposit Crypto{" "}
                        <i class="fa-solid fa-arrow-right-long"></i>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="cstm_form">
                      <div class="mb-4">
                        <label for="Fiat" class="text-muted">
                          Fiat
                        </label>
                        <div className="select_box">
                          {" "}
                          <div
                            className="select_box_inner"
                            // data-bs-toggle="modal"
                            // data-bs-target="#coin_modal"
                          >
                            <div class="d-flex align-items-center">
                              <div>
                                <img
                                  src="https://bithaven.exchange/rupee.png"
                                  alt="coin symbole"
                                  class="coin_img me-2"
                                />
                              </div>
                              <div class="product_name">
                                INR
                                <span className="unit text-muted">RUPEE</span>
                              </div>
                            </div>
                          </div>
                          {/* <div>
                            {" "}
                            <img
                              src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                              class="arrow_img"
                              alt="arrow-icon"
                            />
                          </div> */}
                        </div>
                      </div>

                      <div className="form-group ">
                        <span className=" text-warning">
                          <i className="fas fa-warning" aria-hidden="true"></i>
                          Disclaimer
                        </span>
                        <label className="text-lgray" htmlFor="disclaimer">
                          Please Deposit INR to this address. If you Deposit any
                          other QR or below minimum limit, It will be lost
                          forever.
                        </label>
                      </div>
                      <label for="screenshot">Upload Screenshot File </label>
                      <div className="form-group">
                        {/* <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">@</span>
                      </div> */}
                        <input
                          class="form-control"
                          type="file"
                          onChange={(e) => {
                            uploadIMG(e);
                          }}
                        />
                      </div>
                      <label className="mt-2">Amount</label>
                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        required
                        id="price"
                        value={price}
                        onChange={(e) => {
                          if (e.target.value < 500) {
                            SetIsError("Deposit amount must be at least 500");
                          } else {
                            SetIsError("");
                          }
                          setPrice(e.target.value);
                        }}
                      />
                      {isError && (
                        <div className="text-danger mt-2">{isError}</div>
                      )}
                      <label className="mt-2">Transaction Id</label>
                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        required
                        id="req_no"
                        value={req_no}
                        onChange={(e) => {
                          setReqno(e.target.value);
                        }}
                      />
                      <br />
                      <button
                        className="btn btn-primary py-2"
                        disabled={price < 500}
                        onClick={(e) => {
                          if (controller) {
                            controller.abort();
                          }
                      
                          // Create a new controller for the new request
                          const newController = new AbortController();
                          setController(newController);
                          N_ScreenShot(
                            e,
                            filedata,
                            price,
                            user?.params ? user.params.token : user.token,
                            req_no,
                            newController
                          );
                        }}
                      >
                        Upload
                      </button>
                      <div
                        className="spinner-border text-primary"
                        style={{
                          display: "none",
                        }}
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                      {/* <label for="Fiat" class="text-muted">
                        Payement
                      </label>
                      <div className="mb-4 hero_div border-0 p-4">
                        <p className="mb-0 text-lgray ">
                          <i class="fa-solid fa-location-dot me-2"></i>
                          Payment methods will be available in your region soon.
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-2"></div>

                  <div className="col-lg-4">
                    <div className="box-body card detail-box mt-2 ">
                      

                      <div className="card-body p2p-card-body ">
                      <div className="d-flex gap-2 mt-3">
                       <div> <span className="pt-4">
                          <AiFillBank size={18} />
                        </span></div>
                       <h5> Bank Transfer </h5>
                      </div>
                        <table class="custom_tbl w-100">
                          <tbody>
                            <tr>
                              <td scope="col" className="text-lgray">Bank Name</td>
                              <td>{webData?.bank_name}</td>
                            </tr>
                            <tr>
                              <td scope="col" className="text-lgray">Bank Account Name</td>
                              <td>{webData?.bank_ac_name}</td>
                            </tr>
                            <tr>
                              <td scope="col" className="text-lgray">Bank Account Number</td>
                              <td>{webData?.bank_ac_number}</td>
                            </tr>
                            <tr>
                              <td scope="col" className="text-lgray">Bank IFSC Code</td>
                              <td>{webData?.bank_ifsc_code}</td>
                            </tr>

                            <tr>
                              <td scope="col" className="text-lgray">Bank Branch</td>
                              <td>{webData?.bank_branch}</td>
                            </tr>
                            <tr>
                              <td scope="col" className="text-lgray">UPI ID</td>
                              <td>{webData?.upi}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="mt-3 text-center">
                      <img
                        src=""
                        className="img-fluid mb-4"
                        width="60%"
                      />
                      {/* <p>
                        Payment methods will be available in your region soon.
                      </p> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="flex_row mb-4">
                    <div className="">
                      <div class="">
                        <h3 className=""> Deposit History </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overflow-auto mt-3">
                  <div className="table_scroll_div">
                    <table className="table global_table">
                      <thead>
                        <tr>
                          <th className="text-end">
                            <div className="d-flex align-items-center">
                              <span className="">Symbol</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="">Amount</div>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className=""> Status</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>

                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">Time</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {deposithistory && deposithistory.length > 0 ? (
                          deposithistory.map((item) => {
                            return (
                              <tr>
                                <td>{item?.symbol}</td>
                                <td class="text-end">{item.amount}</td>
                                <td class="text-end">
                                  {item.status ? "success" : "Pending"}
                                </td>

                                <td class="text-end">
                                  {new Date(item.createdAt).toLocaleString()}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              <img
                                src="/img/no-data.png"
                                className="no-data-found"
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div class="modal coin_modal fade" tabindex="-1" id="coin_modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Coin</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="m-1">
                <div class="input-group ">
                  <span class="input-group-text bg-transparent">
                    <div class="">
                      <i class="fa fa-search text-lgray"></i>
                    </div>
                  </span>
                  <input
                    type="text"
                    class="form-control text-start mp-exide-form bg-white"
                    aria-label="Text input with checkbox"
                    placeholder="Search "
                  />
                </div>
              </div>

              <div className="mt-4">
                <h6 className="text-lgray">Popular</h6>
                <p class="fs-14">
                  <span class="label_light me-2">USDT</span>
                  <span class="label_light me-2">BTC</span>
                  <span class="label_light me-2">IRON</span>
                  <span class="label_light me-2">TRX</span>
                  <span class="label_light me-2">ETH</span>
                  <span class="label_light me-2">LTC</span>
                </p>
              </div>

              <div className="custom_scrollbar height250">
                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf91f78afb0a00068efef7_ETH.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">ETH</div>
                    <div class="text-muted fs-12"> Ethereum</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60c34ba0db892b0006d7b0ec_USDT.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">USDT</div>
                    <div class="text-muted fs-12"> Tether</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf8a90db892b0006d73786_BTC.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">BTC</div>
                    <div class="text-muted fs-12"> Bitcoin</div>
                  </div>{" "}
                </div>
                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf91f78afb0a00068efef7_ETH.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">ETH</div>
                    <div class="text-muted fs-12"> Ethereum</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60c34ba0db892b0006d7b0ec_USDT.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">USDT</div>
                    <div class="text-muted fs-12"> Tether</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf8a90db892b0006d73786_BTC.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">BTC</div>
                    <div class="text-muted fs-12"> Bitcoin</div>
                  </div>{" "}
                </div>
                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf91f78afb0a00068efef7_ETH.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">ETH</div>
                    <div class="text-muted fs-12"> Ethereum</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60c34ba0db892b0006d7b0ec_USDT.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">USDT</div>
                    <div class="text-muted fs-12"> Tether</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf8a90db892b0006d73786_BTC.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">BTC</div>
                    <div class="text-muted fs-12"> Bitcoin</div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal coin_modal fade" tabindex="-1" id="network_modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Network</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="m-1">
                <div class="input-group ">
                  <span class="input-group-text bg-transparent">
                    <div class="">
                      <i class="fa fa-search text-lgray"></i>
                    </div>
                  </span>
                  <input
                    type="text"
                    class="form-control text-start mp-exide-form bg-white"
                    aria-label="Text input with checkbox"
                    placeholder="Search "
                  />
                </div>
              </div>

              <div className="mt-4">
                <h6 className="text-lgray">Popular</h6>
                <p class="fs-14">
                  <span class="label_light me-2">USDT</span>
                  <span class="label_light me-2">BTC</span>
                  <span class="label_light me-2">IRON</span>
                  <span class="label_light me-2">TRX</span>
                  <span class="label_light me-2">ETH</span>
                  <span class="label_light me-2">LTC</span>
                </p>
              </div>

              <div className="custom_scrollbar height250">
                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf91f78afb0a00068efef7_ETH.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">ETH</div>
                    <div class="text-muted fs-12"> Ethereum</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60c34ba0db892b0006d7b0ec_USDT.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">USDT</div>
                    <div class="text-muted fs-12"> Tether</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf8a90db892b0006d73786_BTC.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">BTC</div>
                    <div class="text-muted fs-12"> Bitcoin</div>
                  </div>{" "}
                </div>
                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf91f78afb0a00068efef7_ETH.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">ETH</div>
                    <div class="text-muted fs-12"> Ethereum</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60c34ba0db892b0006d7b0ec_USDT.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">USDT</div>
                    <div class="text-muted fs-12"> Tether</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf8a90db892b0006d73786_BTC.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">BTC</div>
                    <div class="text-muted fs-12"> Bitcoin</div>
                  </div>{" "}
                </div>
                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf91f78afb0a00068efef7_ETH.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">ETH</div>
                    <div class="text-muted fs-12"> Ethereum</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60c34ba0db892b0006d7b0ec_USDT.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">USDT</div>
                    <div class="text-muted fs-12"> Tether</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf8a90db892b0006d73786_BTC.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">BTC</div>
                    <div class="text-muted fs-12"> Bitcoin</div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
