import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import { N_transectionHistory } from "./redux/helpers/api_functions_new";

export default function AssetsHistory(props) {
  const { coins } = useSelector((state) => state.coinDBReducer);
  const { user } = useSelector((state) => state.AuthReducer);

  const [transactionHistory, setTransactionHistory] = useState([]);
  const [symbol, setSymbol] = useState("");
  const [type, setType] = useState("");
  const [start_date, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalPages, setTotalPages] = useState(0);

  const fetchTransactionHistory = () => {
    const token = user?.params ? user.params.token : user.token;
    let data = { page, limit };

    if (symbol) data.symbol = symbol;
    if (type) data.type = type;
    if (start_date) data.start_date = start_date;
    if (endDate) data.end_date = endDate;

    N_transectionHistory(data, token)
      .then((response) => {
        if (response.status === 200) {
          setTransactionHistory(response?.result);
          setTotalPages(response.pagination?.totalPages || 0);
        }
      })
      .catch((error) => {
        console.error("Error fetching transaction history:", error);
      });
  };

  useEffect(() => {
    fetchTransactionHistory();
  }, [page, limit, symbol, type, start_date, endDate]);

  const handlePageChange = (direction) => {
    if (direction === "prev" && page > 1) setPage(page - 1);
    if (direction === "next" && page < totalPages) setPage(page + 1);
  };

  const downloadCsv = () => {
    const headers = "Symbol,Type,Amount,Address,Status,Time\n";
    const rows = transactionHistory
      .map((item) => {
        const address = item.to_address || item.from_address;
        const status = item.status ? "Success" : "Pending";
        const time = new Date(item.createdAt).toLocaleString();
        return `${item.symbol},${item.type},${item.amount},${address},${status},${time}`;
      })
      .join("\n");

    const csvContent = `data:text/csv;charset=utf-8,${headers}${rows}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "transaction_history.csv");
    document.body.appendChild(link);
    link.click();
  };

  const coinData =
    coins &&
    Object.values(coins).map((item) => (
      <option key={item.symbol} value={item.symbol}>
        {item.symbol}
      </option>
    ));

  return (
    <>
      <Header />
      <div className="s-layout">
        <div className="s-layout__sidebar">
          <AssetSidebar />
        </div>
        <main className="s-layout__content">
          <div className="marginTop">
            <div className="account_page">
              <div className="">
                <div className="page-content-title">
                  <h3 className="">Assets History</h3>
                </div>

                <div className="row cstm_form">
                  <div className="col-lg-2 mb-3">
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-2 mb-3">
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-2 mb-3">
                    <select
                      className="form-select"
                      onChange={(e) => setSymbol(e.target.value)}
                    >
                      <option value="">All Coins</option>
                      {coinData}
                    </select>
                  </div>
                  <div className="col-lg-2 mb-3">
                    <select
                      className="form-select"
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="">All Types</option>
                      <option value="deposit">Deposit</option>
                      <option value="withdrawal">Withdrawal</option>
                    </select>
                  </div>
                  <div className="col-lg-2 mb-3">
                    <button
                      className="action-btn"
                      onClick={downloadCsv}
                    >
                      Download CSV
                    </button>
                  </div>
                </div>

                <div className="table_scroll_div">
                  <table className="table global_table">
                    <thead>
                      <tr>
                        <th>Symbol</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Address</th>
                        <th>Status</th>
                        <th className="text-end">Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionHistory && transactionHistory.length > 0 ? (
                        transactionHistory.map((item, index) => (
                          <tr key={index}>
                            <td>{item?.symbol}</td>
                            <td>{item?.type}</td>
                            <td>{item?.amount}</td>
                            <td>{item?.to_address || item?.from_address}</td>
                            <td>
                              {item?.status === 1 ? "Success" : "Pending"}
                            </td>
                            <td className="text-end">
                              {new Date(item?.createdAt).toLocaleString()}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="pagination-controls mt-3 d-flex justify-content-center">
                  <button
                    className="btn btn-secondary me-2"
                    disabled={page === 1}
                    onClick={() => handlePageChange("prev")}
                  >
                    Previous
                  </button>
                  <span>
                    Page {page} of {totalPages}
                  </span>
                  <button
                    className="btn btn-secondary ms-2"
                    disabled={page === totalPages}
                    onClick={() => handlePageChange("next")}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
