import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import "./sidebar.css";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import {
  N_GetAllSymbol,
  N_getBlockchainData,
  N_transectionHistory,
} from "./redux/helpers/api_functions_new";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "./redux/helpers/helper_functions";
import { Helmet } from "react-helmet";

export default function AssetsDeposit() {
  const [blockchain, setBlockchain] = useState();
  const [currency, setCurrency] = useState();
  const [contract, setContract] = useState();
  const [coindata, setCoinData] = useState();
  const [wallets, setWallets] = useState();
  const [deposithistory, setDepositHistory] = useState();
  const [symbolData, setSymbolData] = useState();
  const [isSelected, setIsSelected] = useState(0);
  const [search, setSearch] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const { user } = useSelector((state) => state.AuthReducer);
  const [isverified, setIsverified] = useState(null);
  const [SuccessMessage, setSuccessMessage] = useState("");

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    // N_getBlockchain().then((res) => {
    //   if (res.status == 200) {
    //     setBlockchain(res.data);
    //   }
    // });
    N_GetAllSymbol().then((res) => {
      setWallets(res);
      // const coinsData = res.find((item) => item.symbol == "USDT");
      // setCoinData(coinsData);
      // setBlockchain(coinsData.contractAddress);
    });
  }, []);
  useEffect(() => {
    if (coindata) {
      const token = user?.params ? user.params.token : user.token;
      let data = {
        type:"deposit",
        symbol: coindata.symbol,
      };
      N_transectionHistory(data, token)
        .then((data) => {
          if (data.status === 200) {
            setDepositHistory(data?.result);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [coindata]);
  const walletsdata =
    wallets &&
    wallets.filter((product) => {
      return product.symbol.includes(search);
    });
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setSuccessMessage("You have successfully copied Address");
    setTimeout(() => {
      setSuccessMessage("");
    }, 2000); // 2 seconds

    // NotificationManager.success("Copied");
  };

  const buttonStyle = {
    cursor: "pointer",
  };
  return (
    <>
      <div className="application">
        <Helmet>
          <meta
            name="description"
            content="Safely deposit your assets on BITHAVEN Exchange. We prioritize security and
efficiency for a seamless crypto trading experience."
          />
          <title>Secure Asset Deposits: BITHAVEN Exchange </title>
        </Helmet>
      </div>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="container">
            <div className="marginTop">
              <div className="account_page">
                <div className="flex_row mb-4">
                  <div>
                    <h4 className=""> Deposit Crypto </h4>
                  </div>

                  <div>
                    <Link to="/AssetsFiat" className="btn btn-light">
                      Deposit Fiat{" "}
                      <i class="fa-solid fa-arrow-right-long ms-1"></i>
                    </Link>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-7">
                    <div class="accordion verification_accrodion">
                      <div class="timeline_container">
                        <div>
                          <div class="accor_timeline_no">
                            {isverified == 0 || isverified == 1 ? (
                              <i class="fa-solid fa-check text-success"></i>
                            ) : (
                              <i class="fa-solid">1</i>
                            )}
                          </div>
                          <div class="accor_timeline_line"></div>
                        </div>

                        <div class="accordion-item border-0">
                          <div class="">
                            <h2 class="accordion-header" id="headingOne">
                              <button
                                class="accordion-button p-0"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                Select Coin
                              </button>
                            </h2>

                            <div
                              id="collapseOne"
                              class="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                            >
                              <div class="accordion-body px-0">
                                <div className="mb-5">
                                  <div class="select_box asset_dropdown_wrapper">
                                    <div
                                      className="asset_dropdown dropdown-toggle "
                                      id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      {coindata ? (
                                        <div class="d-flex align-items-center">
                                          <div>
                                            <img
                                              src={coindata?.icon}
                                              alt="coin symbole"
                                              class="coin_img me-2"
                                            />
                                          </div>
                                          <div class="product_name">
                                            {coindata?.symbol}{" "}
                                            <span className="unit text-muted">
                                              {coindata?.name}
                                            </span>
                                          </div>{" "}
                                        </div>
                                      ) : (
                                        <div class="d-flex align-items-center">
                                          Please Select
                                        </div>
                                      )}

                                      <div>
                                        <img
                                          src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                                          class="arrow_img"
                                          alt="arrow-icon"
                                        />
                                      </div>
                                    </div>
                                    <ul
                                      class="dropdown-menu asset_dropdown_menu"
                                      aria-labelledby="dropdownMenuButton1"
                                    >
                                      <div class="">
                                        <div class="m-1">
                                          <div class="input-group ">
                                            <span class="input-group-text bg-transparent">
                                              <div class="">
                                                <i class="fa fa-search text-lgray"></i>
                                              </div>
                                            </span>
                                            <input
                                              type="text"
                                              class="form-control text-start mp-exide-form bg-light"
                                              aria-label="Text input with checkbox"
                                              placeholder="Search "
                                              onChange={(e) => {
                                                setSearch(
                                                  e.target.value.toUpperCase()
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>

                                        {/* <div className="mt-4">
                                          <h6 className="text-lgray">
                                            Popular
                                          </h6>
                                          <p class="fs-14">
                                            {wallets &&
                                              wallets.map((item) => {
                                                if (
                                                  item.symbol == "USDT" ||
                                                  item.symbol == "ETH" ||
                                                  item.symbol == "BNB" ||
                                                  item.symbol == "TRX"
                                                ) {
                                                  return (
                                                    <span
                                                      class="label_light me-2"
                                                      onClick={() => {
                                                        setCoinData(item);
                                                        setIsSelected(false);
                                                        setSymbolData();
                                                        setIsverified(0);
                                                      }}
                                                    >
                                                      {item.symbol}
                                                    </span>
                                                  );
                                                }
                                              })}
                                          </p>
                                        </div> */}

                                        <div className="custom_scrollbar height250">
                                          {walletsdata &&
                                            walletsdata.map((item) => {
                                              if (item.symbol !== "INR") {
                                                return (
                                                  <div
                                                    class="select_options"
                                                    onClick={() => {
                                                      setCoinData(item);
                                                      setIsSelected(false);
                                                      setSymbolData("");
                                                      setBlockchain(
                                                        item.contractAddress
                                                      );
                                                      setIsverified(0);
                                                    }}
                                                  >
                                                    <div>
                                                      <img
                                                        src={item.icon}
                                                        alt="coin symbole"
                                                        class="market_coin_img"
                                                      />
                                                    </div>
                                                    <div class="product_name">
                                                      <div class="mb-0 fw-bold">
                                                        {item.symbol}
                                                      </div>
                                                      <div class="text-muted fs-12">
                                                        {item.name}
                                                      </div>
                                                    </div>{" "}
                                                  </div>
                                                );
                                              }
                                            })}
                                        </div>
                                      </div>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="timeline_container">
                        <div>
                          <div class="accor_timeline_no">
                            {isverified == 1 ? (
                              <i class="fa-solid fa-check text-success"></i>
                            ) : (
                              <i class="fa-solid">2</i>
                            )}
                          </div>
                          <div class="accor_timeline_line"></div>
                        </div>

                        <div class="accordion-item border-0">
                          <h2 class="accordion-header" id="headingTwo">
                            <button
                              class={`accordion-button ${
                                isverified == 0 ? "" : "collapsed"
                              }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                              disabled={
                                isverified == 0 || isverified == 1
                                  ? ""
                                  : "disabled"
                              }
                            >
                              Select Network
                            </button>
                          </h2>

                          <div
                            id="collapseTwo"
                            class={`accordion-collapse collapse ${
                              isverified == 0 || isverified == 1 ? "show" : ""
                            }`}
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body px-0">
                              <div className="mb-5">
                                <div class="asset_dropdown_wrapper select_box">
                                  <div
                                    className="asset_dropdown dropdown-toggle"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <div class="product_name">
                                      {symbolData
                                        ? symbolData.symbol +
                                          " (" +
                                          symbolData.contract_type.toUpperCase() +
                                          ")" +
                                          capitalizeFirstLetter(
                                            symbolData.blockchain
                                          )
                                        : "Select Network"}
                                    </div>
                                    <div>
                                      <img
                                        src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                                        class="arrow_img"
                                        alt="arrow-icon"
                                      />
                                    </div>
                                  </div>
                                  <ul
                                    class="dropdown-menu asset_dropdown_menu"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <div class="">
                                      <div className="alert alert-warning">
                                        <div class="d-flex">
                                          <div>
                                            <i className="fa fa-exclamation-circle me-2 text-warning"></i>
                                          </div>
                                          <div className="text-lgray">
                                            Ensure that the selected deposit
                                            network is the same as the
                                            withdrawal network. Otherwise, your
                                            assets could be lost.
                                          </div>
                                        </div>
                                      </div>

                                      <div className="custom_scrollbar height275">
                                        {blockchain &&
                                          blockchain.map((item) => {
                                            return (
                                              <div
                                                class="select_options"
                                                onClick={() => {
                                                  setSymbolData(item);
                                                  N_getBlockchainData(
                                                    item?.blockchain,
                                                    user?.params
                                                      ? user.params.token
                                                      : user.token,
                                                    coindata?.symbol
                                                  ).then((res) => {
                                                    if (res.status == 200) {
                                                      if (item.is_deposit) {
                                                        setCurrency(
                                                          res.currencydata
                                                        );
                                                        setContract(
                                                          res.contractdata
                                                        );
                                                        setIsSelected(1);
                                                      } else {
                                                        setIsSelected(3);
                                                      }
                                                      setIsverified(1);
                                                    }
                                                  });
                                                }}
                                              >
                                                <div class="product_name">
                                                  <div class="mb-0 fw-bold">
                                                    {item.symbol}
                                                    {item.contract_type ==
                                                    "erc20"
                                                      ? "(ERC20)"
                                                      : item.contract_type ==
                                                        "trc20"
                                                      ? "(TRC20)"
                                                      : item.contract_type ==
                                                        "bep20"
                                                      ? " Smart Chain(BEP20)"
                                                      : ""}
                                                  </div>
                                                  <div class="text-muted fs-12">
                                                    {" "}
                                                    {item.blockchain}
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="timeline_container">
                        <div>
                          <div class="accor_timeline_no">
                            <i class="fa-solid">3</i>
                          </div>
                          {/* <div class="accor_timeline_line"></div> */}
                        </div>

                        <div class="accordion-item border-0">
                          <h2 class="accordion-header" id="headingThree">
                            <button
                              class={`accordion-button ${
                                isverified == 1 ? "" : "collapsed"
                              }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                              disabled={isverified == 1 ? "" : "disabled"}
                            >
                              Deposit Address
                            </button>
                           
                          </h2>

                          <div>
                              {SuccessMessage && (
                                <span
                                  className="mb-0 fs-14 text-success"
                                  id="msg"
                                >
                                  {SuccessMessage}
                                </span>
                              )}
                            </div>

                          <div
                            id="collapseThree"
                            class={`accordion-collapse collapse ${
                              isverified == 1 ? "show" : ""
                            }`}
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body px-0">
                              {isSelected == 1 ? (
                                <>
                                  <div className="jambotron-outline mb-4">
                                    <div className="row">
                                      <div className="col-lg-3">
                                        <div className="p-2 shadow-sm border rounded">
                                          <div className="card-body">
                                            <img
                                              src={`https://api.qrserver.com/v1/create-qr-code/?data=${currency?.wallet_address}&amp;size=50x50`}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-9 m-auto">
                                        <div className="mt-4">
                                          <p className="text-lgray mb-2">
                                            Wallet Address
                                          </p>

                                          <div className="d-flex justify-content-between">
                                            <div style={{ width: "366px" }}>
                                              <h5 className="text-break">
                                                {currency?.wallet_address}
                                              </h5>
                                            </div>
                                            <div>
                                              <h5>
                                                <span className="anchor_link">
                                                  <i
                                                    className="fa fa-copy ms-2 cursor_pointer"
                                                    onClick={() => {
                                                      copyToClipboard(
                                                        currency?.wallet_address
                                                      );
                                                    }}
                                                  ></i>
                                                </span>
                                                <span></span>
                                              </h5>
                                            </div>
                                          </div>
                                        </div>
                                        <p className="text-lgray fs-12">
                                          Confirm that your network is
                                          <span className="text-primary ms-1">
                                            {contract?.blockchain}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Read more details starts */}

                                  <div className="col-12">
                                    {isSelected == 1 &&
                                    contract?.contract_address ? (
                                      <>
                                        <div className="d-flex  justify-content-between align-items-center">
                                          <p className="text-lgray mb-2">
                                            Contract Address
                                          </p>

                                          <div className="d-flex justify-content-between">
                                            <div>
                                              <p className="text-break">
                                                {contract?.contract_address}
                                              </p>
                                            </div>
                                            <div>
                                              <span className="text-lgray">
                                                <i
                                                  className="fa fa-copy ms-2 cursor_pointer"
                                                  onClick={() => {
                                                    copyToClipboard(
                                                      contract.contract_address
                                                    );
                                                  }}
                                                ></i>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>

                                  <div className="">
                                    {isVisible && (
                                      <>
                                        <div className="d-flex  justify-content-between align-items-center">
                                          <p className="text-lgray">
                                            Minimum Deposit
                                          </p>

                                          <p className="text-break">
                                            More than XXXX USDT
                                          </p>
                                        </div>

                                        <div className="d-flex  justify-content-between align-items-center">
                                          <p className="text-lgray">
                                            Wallet Selected
                                          </p>

                                          <p className="text-break">Metamask</p>
                                        </div>

                                        <div className="d-flex  justify-content-between align-items-center">
                                          <p className="text-lgray">
                                            Expected Arrival
                                          </p>

                                          <p className="text-break">
                                            After one network confirmations
                                          </p>
                                        </div>

                                        <div className="d-flex  justify-content-between align-items-center">
                                          <p className="text-lgray">
                                            Minimum Unblock Confirmation
                                          </p>

                                          <p className="text-break">
                                            After one network confirmations
                                          </p>
                                        </div>
                                        <hr />

                                        <p className="small">
                                          Deposit via Smart Contract are not
                                          supported with the exception of ETH
                                          via ERC20, Arbitrum & O optimism
                                          network or BNB via BSC network
                                        </p>
                                      </>
                                    )}

                                    <div className="text-center">
                                      <button
                                        className="btn text-lgray border-0"
                                        onClick={toggleVisibility}
                                      >
                                        {isVisible ? (
                                          <>
                                            Read less <IoIosArrowUp />
                                          </>
                                        ) : (
                                          <>
                                            More Details <IoIosArrowDown />
                                          </>
                                        )}
                                      </button>
                                    </div>
                                  </div>

                                  {/* Read more details ends */}
                                </>
                              ) : isSelected == 3 ? (
                                <div className="jambotron-outline mb-4">
                                  <div>
                                    <h5>
                                      <small className="text-lgray">
                                        Deposits are temporarily unavailable.
                                        Any pending deposit orders will be
                                        processed as soon as possible after
                                        deposits come back online.
                                      </small>
                                    </h5>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-4">
                    <h5>FAQs</h5>

                    <div class="lrtcss-ifarjv">
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 fs-12 text-lgray"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            {" "}
                            How do I deposit crypto into my BITHAVEN account?{" "}
                          </a>
                        </div>
                      </p>
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            {" "}
                            What should I do if I didn't receive my deposits or
                            I deposit to an incorrect address?{" "}
                          </a>
                        </div>
                      </p>
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            What should I do if I deposit the wrong crypto?{" "}
                          </a>
                        </div>
                      </p>
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            What should I do if I forgot to specify the Memo,
                            Tag, or Message for my deposit?
                          </a>
                        </div>
                      </p>
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            {" "}
                            What should I do if I mistakenly deposit through the
                            BSC or BEP20 network and did not receive the
                            deposit?
                          </a>
                        </div>
                      </p>
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            What are the common deposit networks?
                          </a>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mt-5">
                  <div className="flex_row mb-5">
                    <div className="">
                      <div class="">
                        <h4 className=""> Deposit History </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overflow-auto mt-3">
                  <div className="table_scroll_div">
                    <table className="table global_table">
                      <thead>
                        <tr>
                          <th className="text-end">
                            <div className="d-flex align-items-center">
                              <span className="">Symbol</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="">Amount</div>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">Address</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className=""> Status</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>

                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">Time</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {deposithistory && deposithistory.length > 0 ? (
                          deposithistory.map((item) => {
                            return (
                              <tr>
                                <td>{item?.symbol}</td>
                                <td class="text-end">{item.amount}</td>
                                <td class="text-end">{item.to_address}</td>
                                <td class="text-end">
                                  {item.status === true ? "success" : ""}
                                </td>

                                <td class="text-end">
                                  {new Date(item.createdAt).toLocaleString()}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              <img
                                src="/img/no-data.png"
                                className="no-data-found"
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div class="modal coin_modal fade" tabindex="-1" id="coin_modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Coin</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="">
              <div class="m-1">
                <div class="input-group ">
                  <span class="input-group-text bg-transparent">
                    <div class="">
                      <i class="fa fa-search text-lgray"></i>
                    </div>
                  </span>
                  <input
                    type="text"
                    class="form-control text-start mp-exide-form bg-light"
                    aria-label="Text input with checkbox"
                    placeholder="Search "
                    onChange={(e) => {
                      setSearch(e.target.value.toUpperCase());
                    }}
                  />
                </div>
              </div>

              <div className="mt-4">
                <h6 className="text-lgray">Popular</h6>
                <p class="fs-14">
                  {wallets &&
                    wallets.map((item) => {
                      if (
                        item.symbol == "USDT" ||
                        item.symbol == "ETH" ||
                        item.symbol == "BNB" ||
                        item.symbol == "TRX"
                      ) {
                        return (
                          <span
                            class="label_light me-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                              setCoinData(item);
                              setIsSelected(false);
                              setSymbolData();
                            }}
                          >
                            {item.symbol}
                          </span>
                        );
                      }
                    })}
                </p>
              </div>

              <div className="custom_scrollbar height250">
                {walletsdata &&
                  walletsdata.map((item) => {
                    if (item.symbol !== "INR") {
                      return (
                        <div
                          class="select_options"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            setCoinData(item);
                            setIsSelected(false);
                            setSymbolData("");
                            setBlockchain(item.contractAddress);
                          }}
                        >
                          <div>
                            <img
                              src={item.icon}
                              alt="coin symbole"
                              class="market_coin_img"
                            />
                          </div>
                          <div class="product_name">
                            <div class="mb-0 fw-bold">{item.symbol}</div>
                            <div class="text-muted fs-12">{item.name}</div>
                          </div>{" "}
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal coin_modal fade" tabindex="-1" id="network_modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Select Network</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="alert alert-warning">
                <div class="d-flex">
                  <div>
                    <i className="fa fa-exclamation-circle me-2 text-warning"></i>
                  </div>
                  <div className="text-lgray">
                    Ensure that the selected deposit network is the same as the
                    withdrawal network. Otherwise, your assets could be lost.
                  </div>
                </div>
              </div>

              <div className="custom_scrollbar height275">
                {blockchain &&
                  blockchain.map((item) => {
                    return (
                      <div
                        class="select_options"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          // N_createAddress(
                          //   user?.params ? user.params.token : user.token,
                          //   coindata?.symbol
                          // );
                          setSymbolData(item);
                          N_getBlockchainData(
                            item?.blockchain,
                            user?.params ? user.params.token : user.token,
                            coindata?.symbol
                          ).then((res) => {
                            if (res.status == 200) {
                              if (item.is_deposit) {
                                setCurrency(res.currencydata);
                                setContract(res.contractdata);
                                setIsSelected(1);
                                // } else if (
                                //   item.blockchain == coindata?.blockchain
                                // ) {
                                //   setCurrency(res.currencydata);
                                //   setIsSelected(2);
                                // } else {
                              } else {
                                setIsSelected(3);
                              }
                            }
                          });
                        }}
                      >
                        <div class="product_name">
                          <div class="mb-0 fw-bold">
                            {item.symbol}
                            {item.contract_type == "erc20"
                              ? "(ERC20)"
                              : item.contract_type == "trc20"
                              ? "(TRC20)"
                              : item.contract_type == "bep20"
                              ? " Smart Chain(BEP20)"
                              : item.contract_type == "dtbx20"
                              ? "(DTBX20)"
                              : ""}
                          </div>
                          <div class="text-muted fs-12"> {item.blockchain}</div>
                        </div>{" "}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
